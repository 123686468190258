import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Financing Your Smile | Cosmetic Dentist Toronto | The Smile Stylist" 
    description="There is no need to worry about treatment cost. With financing, those porcelain veneers and that smile makeover is more affordable than you realize." 
    keywords="cosmetic dentist Toronto, cosmetic dentistry, veneers financing" />
    <main className="site-content" role="main">
    <section className="has-image-right_fin _pad_bottom" id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 wow animated fadeInRight">
            <div className="row">
              <div className="col-md-8 col-sm-12 wow animated fadeInRight">
                <h1 className="info__heading_black">Financing Your Smile</h1>
                <p className="info__subheading _black">Ensure that you don't delay your treatment due to cost concerns</p>
                <p className="info__text_spaced">
                  Getting the dream smile that you have always wanted is more achievable than you probably realize. We have partnered up with the best finance providers in the country to ensure that you have every opportunity to get the smile you’ve always wanted without having to over extend yourself.
                </p>
                <p className="info__subheading _red">Applying is confidential, secure and quick. </p>
                <p className="info__text_spaced">To fill out a secure online form
                  <a className="info__link_black" href="https://www.dentalcard.ca/apply-now.php" target="_blank">click here.</a>
                </p>
              </div>
              <div className="col-md-4 hidden-sm">
              </div>
            </div>
            <div className="row">
              <p className="info__heading_black _text-centered">Our Partners</p>
              <div className="col-md-1 hidden-sm"></div>
              <div className="info__sponsors col-md-4 col-sm-12">
                <img alt="Finance Dental" src={require("../images/desktop/logos/dentalcard.jpg")} className="img-responsive img-spons-first _center-hor" />
              </div>
              <div className="info__sponsors col-md-4 col-sm-12">
                <img alt="Cosmediclist" src={require("../images/desktop/logos/cosmediclist.jpg")} className="img-responsive img-spons-second _center-hor" />
              </div>
              <div className="col-md-3 hidden-sm"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage